<template>
  <div>
    <validation-observer ref="simple">
      <b-form>
        <b-card-code title="  إضافة  عهدة">
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <b-row>
              <b-col
                md="4"
                xl="4"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="employe"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label> الموظف</label>
                    <v-select
                      v-model="employe"
                      :options="optionemploye"
                      :reduce="(val) => val.value"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                md="3"
                xl="4"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="date"
                    :state="errors.length > 0 ? false : null"
                  >
                    <label>تاريخ </label>
                    <flat-pickr
                      v-model="date"
                      class="form-control"
                      placeholder="حدد تاريخ "
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                md="3"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="employe"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label> المادة</label>
                    <v-select
                      v-model="item"
                      label="name"
                      :options="optionitem"
                      :reduce="(val) => val.id"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                md="3"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="item_name"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label>  اسم العهدة</label>
                    <b-form-input
                      id="input-default"
                      v-model="item_name"
                      type="text"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                md="3"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="file"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label>   الملف</label>
                    <b-form-file
                      v-model="file"
                      accept=".pdf"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                md="12"
                xl="12"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="notes"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label> التفاصيل</label>
                    <b-form-textarea
                      id="input-default"
                      v-model="body"
                      type="text"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>

              <b-col
                md="1"
                xl="3"
              >
                <b-button
                  variant="purple"
                  @click="addPacts"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  <span class="align-middle"> إضافة العهدة</span>
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </b-card-code>
      </b-form>
    </validation-observer>
    <b-card-code title=" العهد">
      <!-- search input -->

      <div style="
    text-align: end;
    margin-bottom: 22px;
">
       

        <b-button
          variant="purple"
          class="shadow mb-10"
        
        >
        <vue-excel-xlsx
            ref="childComponent"
            :data="datatest"
            :columns="columns"
            :file-name="filename"
            :file-type="'xlsx'"
            :sheet-name="'sheetname'"
            class="button-excel"
          >
            <span> Export To Excel</span>
          </vue-excel-xlsx>
          
        </b-button>

      </div>
          <!-- search input -->
          <div class="custom-search d-flex justify-content-start">
        <b-form-group>
          <div class="">
            <!-- <label>بحث</label> -->
            <div>
              <b-form-input
                v-model="search"
                placeholder="بحث"
                type="text"
                class="d-inline-block"
              />
            </div>
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2 row">
        <b-col
          cols="6"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Show</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>entries</label>

        </b-col>

        <b-col
          cols="6"
          md="6"
          class=""
        >
          <label> {{ total }}      عدد العهد</label>
          <label />

        </b-col>

      </div>

      <b-table
      ref="refListTable"
        class="position-relative"
        :items="fetchData"
        responsive
        :fields="tableColumns"
        :current-page="currentPage"
        :per-page="perPage"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
      <template

          #cell(actions)="data"
        >
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
          <b-dropdown-item-button
                  @click="
                    $router.push({
                      name: 'edit-pacts',
                      params: { id: data.item.id },
                    })
                  "
                >
                  <feather-icon icon="EyeIcon" class="mr-50" />
                  <span>عرض</span>
                </b-dropdown-item-button>
                
                <b-dropdown-item-button @click="deletePacts(data.item.id)">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>حذف</span>
                </b-dropdown-item-button>
          </b-dropdown>
        </template>

         
            </b-table>

          

       
           </b-card>
           <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      
    </b-card-code>
  </div>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { required, max, is_earlier } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'

import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,

  BFormDatepicker,
  BFormCheckbox,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButton,
  BForm,
  BFormInvalidFeedback,
  BPagination,
  BTable,
  BCardHeader, 
  BCardBody,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import useList from "./List";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {

  components: {
    flatPickr,
    ToastificationContent,
    required,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BFormInvalidFeedback,
    VueGoodTable,
    BForm,
    ValidationObserver,
    ValidationProvider,

    BCardCode,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    Cleave,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BRow,

    BCol,
    BPagination,
  BTable,
  BCardHeader, 
  BCardBody,

  },
setup(){
  const {
    search,
      tableColumns,
      perPage,
      currentPage,
      total,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      fetchData,
      

      // Extra Filters
    } = useList()
    
    return {
      fetchData,
      search,
      filterOn: [],
      searchTerm: "",
      tableColumns,
      perPage,
      currentPage,
      total,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
    }

},

  data() {
    return {
      file: '',
      body: '',
      item_name: '',

      filename: '',
      rewardType: '',
      datatest: [],
      columns: [
        {
          label: 'الموظف ',
          field: 'user_name',
        },
        {
          label: 'التاريخ ',
          field: 'date',
        },
        {
          label: ' العهدة ',
          field: 'item_name',
        },

        {
          label: 'الخيارات',
          field: 'id',
          sortable: true,
        },
      ],
      rows: [],
      date: '',
      notes: '',
      employe: '',
      id: '',
      searchTerm: '',
      optionerewardType: [
      ],
      item: '',
      optionitem: [],
      optionemploye: [],
    }
  },

  created() {
    this.getPacts()
    this.getItem()
this.download()
    this.getemploye()
  },

  methods: {
    async download() {
      await this.$http.get('/api/v1/get-user-pacts-for-exporting').then(res => {
     //   console.log(res.data.data)
        this.datatest = res.data.data
        // this.$refs.childComponent.exportExcel()
        // console.log(this.optionemploye);
      })
    },

    getemploye() {
      this.optionemploye = []
      this.$http.get('/api/v1/get-all-users').then(res => {
        // console.log(res);
        const employe = res.data
        employe.forEach(el => {
          this.optionemploye.push({ value: el.id, label: `${el.first_name} ${el.last_name} ` })
        })
        // console.log(this.optionemploye);
      })
    },
    getItem() {
      this.optionitem = []
      this.$http.get('/api/v1/item').then(res => {
        // console.log('itemres',res);
        this.optionitem = res.data.data

        // console.log(this.optionitem);
      })
    },

    deletePacts(id) {
      this.$swal({
        icon: 'warning',
        title: 'هل انت متأكد من الحذف',
        confirmButtonText: 'موافق',
        cancelButtonText: 'الغاء',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'لا',
        },
      }).then(result => {
        if (result.value) {
          const url = `/api/v1/pact/${id}`
          this.$http.delete(url).then(res => {
            // console.log(res);
            this.refetchData();
            this.$swal({
              icon: 'warning',
              title: 'تم الحذف  بنجاح',
              confirmButtonText: 'موافق',
              cancelButtonText: 'الغاء',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'لا',
              },
            })
          })
        }
      })
    },
    addPacts() {
      return new Promise((resolve, reject) => {
        this.$refs.simple
          .validate()
          .then(success => {
            if (success) {
              const formData = new FormData()
              formData.append('user_id', this.employe)
              formData.append('date', this.date)
              formData.append('item_name', this.item_name)
              formData.append('body', this.body)
              formData.append('file', this.file)
              formData.append('item_id', this.item)

              // console.log("formData",formData)
              this.$http.post('/api/v1/pact', formData).then(res => {
                // console.log(res);
                this.refetchData();
                this.$swal({
                  title: '',
                  text: 'تمت أضافة العهدة بنجاح',
                  icon: 'success',
                  confirmButtonText: 'موافق',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
            }
          })
      })
    },

    getPacts() {
      this.$http.get('/api/v1/pact').then(res => {
        // console.log('pact', res.data.data)
        this.rows = res.data.data
      })
    },

    //
  },
}
</script>

<style scoped>
label {
  color: rgba(112, 192, 203, 255);
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #7e7a9f;
  margin-top: 25px;
}
.button-excel {
  border: none;
  padding: 0;
  background: none;
  color: white;
}
span {
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.align-left {
  left: 50px;
}
.but {
  top: 40px;
}
</style>
